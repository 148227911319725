import './styles'
import { useContext, useEffect, useState } from "react";
import Template1 from './jaqmik'
import Template2 from './adkar'
import Template3 from './jeimi'
import Template4 from './katherine'
import Template5 from './marmad'
import Template6 from './franco'
import { Auth } from '../context';
import { Box } from '@mui/material';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useNavigate, useParams } from 'react-router-dom';

const Template = () => {
    const {festejado} = useParams();
    const navigate = useNavigate();

    const [id_plantilla, setPlantilla] = useState(-1)
    const [data, setData] = useState({})

    const authContext = useContext(Auth);
    const { authInvitado, informacion } = authContext;

    useEffect(() => {
        authInvitado();
    },[])

    useEffect(() => {
        if(informacion && festejado){
            let evento_nombre = informacion?.evento?.nombre ? informacion.evento.nombre: "evento_especial",
            sin_espacios = evento_nombre.split(" ").join("");
            setData(informacion)
            if(festejado === "access"){ navigate(`/invitaciones/${sin_espacios.toLowerCase()}`) }else{
                if(festejado !== sin_espacios.toLowerCase()){ navigate(`/invitaciones/${sin_espacios.toLowerCase()}`)}
            }
        }
    },[informacion, festejado])

    useEffect(()=>{
        if(Object.keys(data).length !== 0 && data.constructor === Object){
            setPlantilla(
                data?.id_plantilla || data.id_plantilla == 0 ?
                    data.id_plantilla: -1
            )
        }
    },[data])

    if(id_plantilla > -1){
        switch(id_plantilla){
            case 1:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template1 data={data}/>
                </ParallaxProvider>)
            case 2:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template2 data={data}/>
                </ParallaxProvider>)
            case 3:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template3 data={data}/>
                </ParallaxProvider>)
            case 4:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template4 data={data}/>
                </ParallaxProvider>)
            case 5:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template5 data={data}/>
                </ParallaxProvider>)
            case 6:
                return(<ParallaxProvider className='tmp-1-parallax-title-container' opacity={[0.2, 0.95]} speed={-1}>
                    <Template6 data={data}/>
                </ParallaxProvider>)
            default:
               return(<Box>
                dklsdksl
               </Box>) 
        }
    }else{
        return(<Box>
            dklsdksl
        </Box>)
    }
}

export default Template;
