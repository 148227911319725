export const extract_file = (data_file, multimedia) => {
    let filename = data_file?.s_filename, name = filename.split(".")
        
    if(name.length > 0){
        switch(name[0].toLowerCase()){
            case "banner":
                return {
                    "banner": data_file.s_url
                } 
            case "padres":
                return {
                    "padres": data_file.s_url
                }
            case "recepcion":
                return {
                    "recepcion": data_file.s_url
                }
            case "dresscode":
                return {
                    "dresscode": data_file.s_url
                }
            case "invitado":
                let minuspointer = multimedia.map(mul_pointer => {
                    if(mul_pointer?.s_filename){
                        let filename = mul_pointer?.s_filename
                        let invitado_filter_label = filename.split("invitado")
                        if(invitado_filter_label.length > 1){
                            let invitado_extract = filename.split("&")
                            if(invitado_extract.length > 1){
                                if(mul_pointer?.s_url){ return mul_pointer.s_url 
                                }else{ return false }
                            }else{ return false }
                        }else return false
                    }else{ return false }
                    return false
                })

                let invitados_filtrados = minuspointer.filter(invitado_filtrado => invitado_filtrado)

                if(invitados_filtrados.length > 0){
                    return{
                        "invitado": invitados_filtrados
                    }
                }else{
                    return false
                }
            case "&invitado":
                let guestmultimedia = multimedia.map(mul_pointer => {
                    if(mul_pointer?.s_filename){
                        let filename = mul_pointer?.s_filename
                        let invitado_filter_label = filename.split("invitado")
                        if(invitado_filter_label.length > 1){
                            let invitado_extract = filename.split("&")
                            if(invitado_extract.length > 1){
                                if(mul_pointer?.s_url){ return mul_pointer.s_url 
                                }else{ return false }
                            }else{ return false }
                        }else return false
                    }else{ return false }
                })

                let guest_filter = guestmultimedia.filter(invitado_filtrado => invitado_filtrado)

                if(guest_filter.length > 0){
                    return{
                        invitados: guest_filter
                    }
                }else{
                    return false
                }
            case "galeria":
                    let minuspointer_galeria = multimedia.map(mul_pointer => {
                        if(mul_pointer?.s_filename){
                            let filename = mul_pointer?.s_filename
                            let galeria_filter_label = filename.split("galeria")
                            if(galeria_filter_label.length > 1){
                                let galeria_extract = filename.split("&")
                                if(galeria_extract.length > 1){
                                    if(mul_pointer?.s_url){ return mul_pointer.s_url 
                                    }else{ return false }
                                }else{ return false }
                            }else return false
                        }else{ return false }
                        return false
                    })
    
                    let galeria_filtrados = minuspointer_galeria.filter(galeria_filtrado => galeria_filtrado)
    
                    if(galeria_filtrados.length > 0){
                        return{
                            "galeria": galeria_filtrados
                        }
                    }else{
                        return false
                    }
            case "confirmacion":
                    let minuspointer_confirmation = multimedia.map(mul_pointer => {
                        if(mul_pointer?.s_filename){
                            let filename = mul_pointer?.s_filename
                            let invitado_filter_label = filename.split("confirmacion")
                            if(invitado_filter_label.length > 1){
                                let invitado_extract = filename.split("&")
                                if(invitado_extract.length > 1){
                                    if(mul_pointer?.s_url){ return mul_pointer.s_url 
                                    }else{ return false }
                                }else{ return false }
                            }else return false
                        }else{ return false }
                        return false
                    })
    
                    let confirmacion_filtrados = minuspointer_confirmation.filter(invitado_filtrado => invitado_filtrado)
    
                    if(confirmacion_filtrados.length > 0){
                        return{
                            "confirmacion": confirmacion_filtrados
                        }
                    }else{
                        return false
                    }

            default:
                return false
        }
    }
    return false
}
